@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");

@media only screen and (min-width: 0px) and (max-width: 385px) {
  .respons__btn {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .respons__btn a:nth-child(2) {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 420px) {
  .respons__btn420px {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .respons__btn420px a:nth-child(2) {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 735px) {
  .respons__btn {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .respons__btn a:nth-child(2) {
    margin-top: 2vw;
  }
}

@media only screen and (min-width: 0px) and (max-width: 900px) {
  .youtube_player {
    width: 90vw;
    height: 50vw;
    border-radius: 8px;
  }

  .title_new_driver {
    font-size: 3.5vw !important;
    margin-bottom: 4vw !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 2560px) {
  .youtube_player {
    width: 810px;
    height: 450px;
    border-radius: 8px;
  }

  .title_new_driver {
    font-size: 31px !important;
    margin-bottom: 30px !important;
  }
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
  .youtube_player_shorts {
    width: 70vw;
    height: calc(70vw * 1.776);
    border-radius: 8px;
    border: #000 solid 1px;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 2560px) {
  .youtube_player_shorts {
    width: 300px;
    height: 533px;
    border-radius: 8px;
    border: #000 solid 1px;
    margin-left: 10px;
    margin-top: 20px;
  }
}

/* Scroll style */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  background: transparent;
  box-shadow: 3px 0px 20px -10px #000 inset;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  box-shadow: 3px 0px 15px 1px #6363f5 inset;
}

::-webkit-scrollbar-thumb:active {
  box-shadow: 7px 0px 15px 3px #6363f5 inset;
}

.headButtons:hover {
  color: #797af0;
  cursor: pointer;
}

/* .headButtonsMobile:hover {
  cursor: pointer;
} */

.menuNavlinkButtons {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  margin-top: 10px;
  margin-bottom: 10px;
  transition: ease-in-out 0.15s;
}

.menuNavlinkButtons:hover {
  color: #797af0;
  cursor: pointer;
}

.menuNavlinkButtonsMobile {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  margin-top: 10px;
  margin-bottom: 10px;
  transition: ease-in-out 0.15s;
}

.menuNavlinkDownload {
  transition: 0.2s;
  color: #000;
  cursor: pointer;
  text-decoration: none;
  margin-top: 10px;
  margin-bottom: 10px;
}

.menuNavlinkDownload:hover {
  transition: 0.2s;
  color: #797af0;
  cursor: pointer;
}

.rotate45 {
  transform: rotate(-45deg);
}

.rotate45:hover {
  transform: rotate(-225deg);
  transition: 0.7s;
}

.LetzDriver {
  text-decoration: none;
  color: white;
  transition: ease-in-out 0.2s;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
}

.LetzDriver:hover {
  color: #797af0;
  transition: ease-in-out 0.2s;
}

/* MAIN  */

#storeBtn:hover {
  transform: scale(1.05);
  box-shadow: 5px 5px 10px -3px #000;
}

#storeBtn {
  transition: ease-in-out 0.15s;
}

.linkDriver {
  transition: ease-in-out 0.16s;
}

.linkDriver:hover {
  transform: scale(1.1);
  color: #797af0 !important;
  border-bottom: solid 2px #797af0 !important;
  cursor: pointer;
}

.buttonDriver {
  transition: ease-in-out 0.16s;
}

.buttonDriver:hover {
  cursor: pointer;
  background-color: #2f2e41 !important;
}

p {
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
}

.h2 {
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

.h3 {
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

.AnchorOn {
  position: fixed !important;
  opacity: 0.6;
  right: 10px;
  bottom: 10px;
  border: solid 2px #797af0 !important;
  border-radius: 8px !important;
  color: #7879f0 !important;
  transition: ease-in-out 0.3s;
}

.AnchorOn:hover {
  position: fixed !important;
  opacity: 0.6;
  box-shadow: 5px 5px 10px -3px #000;
  border: solid 2px #797af0;
  border-radius: 8px;
  right: 10px;
  bottom: 10px;
  color: #7879f0 !important;
  transition: ease-in-out 0.3s;
}

.AnchorOff {
  position: fixed !important;
  opacity: 0.6;
  right: -50px;
  bottom: 10px;
  border: solid 2px #797af0 !important;
  border-radius: 8px !important;
  color: #7879f0 !important;
  transition: ease-in-out 0.3s;
}

#Top {
  position: relative;
}

/* DRIVER */

.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
  font-size: "30px";
  font-family: "Montserrat";
  font-weight: 600;
}

#standard-basic {
  font-size: "16px" !important;
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
}

.driver-textarea:hover {
  border: 2px solid #000000;
  padding: 10px 5px !important;
}

/* Label */
.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.Mui-focused {
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
  color: #000000 !important;
}

/* default text input */
.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
}

/* Border color focus input */
.MuiInputBase-root.MuiInput-root.Mui-focused:not(:hover):before {
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
  border-bottom: 2px solid #000000 !important;
}

.MuiInputBase-root.MuiInput-root:after {
  border-bottom: 1px solid #000000 !important;
}

/* Hover cinput border-color */
.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl:hover:not(
    .Mui-disabled
  ):before {
  border-bottom: 2px solid #000000 !important;
}

/*Drawer for mobile */

.MuiDrawer-modal {
  margin-top: 100px !important;
  z-index: 2 !important;
}

.MuiDrawer-paper {
  margin-top: 100px !important;
}
